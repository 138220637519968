<script lang="ts" setup>
import type { FilterGroup } from '../Filters/schema'
import type { FilterConfig } from '~/components/Filters/configs'
import type { TableConfig } from '~/components/TheTable.vue'
import type { AccountRole, FilterTemplateGroup, PrismaOrderBy, PrismaWhere, RouterOutput } from '~/types'
import type { Action } from '~/composables/useRenderButtonPresets'
import type { ExportDataColumn, ExportFormats } from '~/pdfs/helpers'

type Account = RouterOutput['accounts']['findManyAccount']['data'][number]

const props = defineProps<{
  rolesToDisplay?: AccountRole[]
  where?: PrismaWhere<'account'>
  filterConfig?: FilterConfig
  filterGroup?: FilterTemplateGroup
  actions?: Action[]
  showSummary?: boolean
  hideSubAccounts?: boolean
  defaultOrderBy?: PrismaOrderBy<'account'>[]
}>()

const emit = defineEmits<{
  (e: 'update', entity: Account): void
  (e: 'showDetails', entity: Account): void
  (e: 'select', entity: Account): void
}>()

const { canUse } = useAuthorization()
const { fulltextSearchValue, where } = useFilterData({
  filters: ['/person/firstName', '/person/lastName', '/email', '/legalRepresentativeOf/some/firstName', '/legalRepresentativeOf/some/lastName'],
})

const appliedPrismaFilter: Ref<any | undefined> = ref(undefined)
const filterDetails: Ref<FilterGroup | undefined> = ref(undefined)

const defaultOrderBy: ComputedRef<PrismaOrderBy<'account'>[]> = computed(() => props.defaultOrderBy ?? [{ person: { firstName: 'asc' } }, { person: { lastName: 'asc' } }])
const orderBy: Ref<PrismaOrderBy<'account'>[] | undefined> = ref()
const { serverSidePaginationTableProps, serverSidePagination, watchForPageCount } = useTablePagination()
const queryParams = useQueryParams('account', {
  where: computed(() => ({
    ...where.value,
    ...appliedPrismaFilter.value,
    ...props.where,
    ...(props.rolesToDisplay && {
      role: {
        in: props.rolesToDisplay,
      },
    }),
  })),
  pagination: serverSidePagination,
  orderBy: computed(() => orderBy.value ?? defaultOrderBy.value),
})

const { accountQuery } = useQuery()
const { data: accountsData, isLoading: areAccountsLoading } = accountQuery.findMany(computed(() => ({
  ...queryParams.value,
  hideSubAccount: props.hideSubAccounts,
})))

const accounts = computed(() => accountsData?.value?.data)
watchForPageCount(computed(() => accountsData.value?.count ?? 0))

const { $i18n } = useNuxtApp()
const tableConfig: TableConfig<Account> = {
  columns: [
    {
      title: $i18n.t('account.name.label'),
      key: 'person.firstName+person.lastName',
      render: ({ person }) => `${person?.firstName} ${person?.lastName}`,
      sorter: 'default',
    },
    {
      title: $i18n.t('account.email.label'),
      key: 'email',
      sorter: 'default',
    },
    {
      title: $i18n.t('account.role.label'),
      key: 'role',
      render: ({ role }) => role && $i18n.t(`roles.${role}`),
      sorter: 'default',
    },
    {
      title: $i18n.t('account.createdAt.label'),
      key: 'person.createdAt',
      render: ({ person }) => person?.createdAt && $i18n.d(person?.createdAt, 'dateTime'),
      sorter: 'default',
    },
    {
      title: $i18n.t('account.lastLoginAt.label'),
      key: 'lastLoginAt',
      render: ({ lastLoginAt }) => lastLoginAt && $i18n.d(lastLoginAt, 'dateTime'),
      sorter: 'default',
    },
  ],
  actionButtons: (row) => {
    if (props.actions) {
      return props.actions
    }
    const actions: Action[] = ['showDetails']
    if (row.email) {
      actions.push('update')
    }
    return actions
  },
  rowProps: row => ({
    style: 'cursor: pointer;',
    onClick: (e) => {
      if (!isClick().isCollapseArrowClick(e)) {
        emit('showDetails', row)
      }
    },
  }),
}
const { isExporting, exportData, formatters } = useExport()
const exportColumns = computed<ExportDataColumn[]>(() => [
  { pointer: '/person/firstName', title: $i18n.t('account.name.label'), render: ({ person }) => `${person?.firstName} ${person?.lastName}` },
  { pointer: '/person/birthDate', title: $i18n.t('person.birthDate.label'), formatter: formatters.date('numeric') },
  { pointer: '/email', title: $i18n.t('account.email.label') },
  { pointer: '/mobilePhone', title: $i18n.t('person.mobilePhone.label') },
  { pointer: '/landLinePhone', title: $i18n.t('person.landLinePhone.label') },
  { pointer: '/person/address/street', title: $i18n.t('person.address.street.label') },
  { pointer: '/person/address/postcode', title: $i18n.t('person.address.postcode.label') },
  { pointer: '/person/address/city', title: $i18n.t('person.address.city.label') },
  { pointer: '/person/gender', title: $i18n.t('person.gender.label'), formatter: formatters.translation('person.gender') },
  { pointer: '/person/isPregnant', title: $i18n.t('person.isPregnant.label'), formatter: formatters.translation('person.isPregnant') },
  { pointer: '/person/isSmoker', title: $i18n.t('person.isSmoker.label'), formatter: formatters.translation('person.isSmoker') },
  { pointer: '/person/weightInKg', title: $i18n.t('person.weight.label'), formatter: formatters.number() },
  { pointer: '/person/heightInCm', title: $i18n.t('person.height.label'), formatter: formatters.number() },
  { pointer: '/person/bmi', title: $i18n.t('person.bmi.label'), formatter: formatters.number() },
  { pointer: '/person/relevantPredispositions', title: $i18n.t('person.relevantPredispositions.label'), formatter: formatters.array('name') },
  { pointer: '/person/livingSituation', title: $i18n.t('person.livingSituation.label') },
  { pointer: '/createdAt', title: $i18n.t('case.createdAt.label'), formatter: formatters.date('dateTime') },
  { pointer: '/lastLoginAt', title: $i18n.t('account.lastLoginAt.label'), formatter: formatters.date('dateTime') },
])
const { $trpc } = useNuxtApp()
const handleExport = async (format: ExportFormats) => {
  isExporting.value = true
  const data = await $trpc.exports.exportPersonsTable.query({ where: queryParams.value.where })
  exportData({ type: format, data, columns: exportColumns.value, params: {
    filtersDescription: props.filterConfig ? useFiltersTranslator(props.filterConfig, filterDetails.value, fulltextSearchValue.value) : undefined,
    dataEntity: $i18n.t('person.entity.plural'),
    headerTextSize: 8,
    cellTextSize: 6,
  } })
}
</script>

<template>
  <FiltersBuilder
    v-if="canUse('filter.use') && filterConfig && filterGroup"
    :filter-config="filterConfig"
    :filter-group="filterGroup"
    @update="appliedPrismaFilter = $event"
    @filter-details="filterDetails = $event"
  />
  <n-input-group class="w-full flex items-center">
    <TheSearchBar
      v-model="fulltextSearchValue"
      class="flex w-full items-center gap-2"
    />
    <TheExportButton v-if="canUse('person.export')" :is-loading="isExporting" @export="handleExport" />
  </n-input-group>
  <TheTable
    v-bind="serverSidePaginationTableProps"
    :data="accounts"
    :table-config="tableConfig"
    :is-loading="areAccountsLoading"
    :show-summary="showSummary"
    @show-details="row => $emit('showDetails', row)"
    @select="row => $emit('select', row)"
    @update="row => $emit('update', row)"
    @update:sorter="(sorter) => {
      orderBy = useTableSorter<'account'>(sorter)
      serverSidePaginationTableProps.controlledPaginationSettings.page = 1
    }"
  />
</template>
